<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-md-auto g-2 justify-content-end"
      >
        <div class="col">
          <select aria-label="" v-model="searchData.pagesize">
            <option value="10" selected>
              {{ $t("filter.common.sort_by_10") }}
            </option>
            <option value="50">{{ $t("filter.common.sort_by_50") }}</option>
            <option value="100">
              {{ $t("filter.common.sort_by_100") }}
            </option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select Mini Game Type"
            name="type"
            v-model="searchData.type"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("filter.support.manual") }}</option>
            <option value="2">{{ $t("filter.support.promotion") }}</option>
            <option value="3">{{ $t("filter.support.printed") }}</option>
            <option value="4">{{ $t("filter.support.other") }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-6 col-md-auto">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.searchtype"
          >
            <option value="1">{{ $t("filter.common.title") }}</option>
            <option value="2">{{ $t("filter.common.contents") }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-6 col-md-auto">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <table class="table board resrc-list">
        <thead>
          <tr>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.number") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.type") }}
            </th>
            <th class="d-lg-table-cell">{{ $t("title.common.title") }}</th>
            <th class="d-none d-sm-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.support.view_count") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.support.download") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in topItems">
            <tr :key="index">
              <td class="d-none d-lg-table-cell">*</td>
              <td class="d-none d-lg-table-cell">
                <span class="icon badge bg-purple" v-if="item.type == 1">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-blue" v-if="item.type == 2">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-pink" v-if="item.type == 3">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-cyan" v-if="item.type == 4">
                  {{ item.type | category("list") }}
                </span>
              </td>
              <td class="d-lg-table-cell text-start">
                <div class="d-flex align-items-center">
                  <router-link
                    :to="{
                      name: 'ResourcesView',
                      params: { id: item.resourcesno },
                      query: {
                        searchtype: searchData.searchtype,
                        searchname: searchData.searchname,
                      },
                    }"
                    class="text-truncate more"
                  >
                    {{ item.title }}
                  </router-link>
                  <div class="ms-1 ms-md-2">
                    <img
                      src="/img/new.gif"
                      v-if="
                        $moment
                          .duration($moment().diff($moment(item.date)))
                          .asDays() < 8
                      "
                    />
                  </div>
                </div>
              </td>
              <td class="d-none d-sm-table-cell w-1">
                <p class="text-date" v-if="item.timezone_date.timezone_private">
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-orange"
                    title="Connect time"
                    >CT</dfn
                  >
                  {{ item.timezone_date.timezone_private | dateformat }}
                </p>
                <p
                  class="text-date"
                  v-else-if="item.timezone_date.timezone_common"
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >
                  {{ item.timezone_date.timezone_common | dateformat }}
                </p>
              </td>
              <td class="d-none d-lg-table-cell">
                {{ item.cnt | comma }}
              </td>
              <td class="d-none d-lg-table-cell">
                <a
                  v-if="item.filename"
                  class="btn btn-outline-primary btn-xs"
                  :href="item.download_link"
                  download
                >
                  {{ $t("button.support.download") }}
                </a>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="6">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.number") }}</div>
                    <div class="col-8">*<!-- {{ item.no }} --></div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.type") }}</div>
                    <div class="col-8">
                      <span class="icon badge bg-purple" v-if="item.type == 1">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-blue" v-if="item.type == 2">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-pink" v-if="item.type == 3">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-cyan" v-if="item.type == 4">
                        {{ item.type | category("list") }}
                      </span>
                    </div>
                  </div>
                  <div class="row d-sm-none">
                    <div class="col-4">
                      {{ $t("title.common.date") }}
                    </div>
                    <div class="col-8">
                      <p
                        class="text-date"
                        v-if="item.timezone_date.timezone_private"
                      >
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-orange"
                          title="Connect time"
                          >CT</dfn
                        >
                        {{ item.timezone_date.timezone_private | dateformat }}
                      </p>
                      <p
                        class="text-date"
                        v-else-if="item.timezone_date.timezone_common"
                      >
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >
                        {{ item.timezone_date.timezone_common | dateformat }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.support.view_count") }}
                    </div>
                    <div class="col-8">
                      {{ item.cnt | comma }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.support.download") }}</div>
                    <div class="col-8">
                      <a
                        v-if="item.filename"
                        class="btn btn-outline-primary btn-xs"
                        :href="item.download_link"
                        download
                      >
                        {{ $t("button.support.download") }}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-for="(item, index) in items">
            <tr :key="index + 'l'">
              <td class="d-none d-lg-table-cell">{{ item.no }}</td>
              <td class="d-none d-lg-table-cell">
                <span class="icon badge bg-purple" v-if="item.type == 1">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-blue" v-if="item.type == 2">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-pink" v-if="item.type == 3">
                  {{ item.type | category("list") }}
                </span>
                <span class="icon badge bg-cyan" v-if="item.type == 4">
                  {{ item.type | category("list") }}
                </span>
              </td>
              <td class="d-lg-table-cell text-start">
                <div class="d-flex align-items-center">
                  <router-link
                    :to="{
                      name: 'ResourcesView',
                      params: { id: item.resourcesno },
                      query: {
                        searchtype: searchData.searchtype,
                        searchname: searchData.searchname,
                      },
                    }"
                    class="text-truncate more"
                  >
                    {{ item.title }}
                  </router-link>
                  <div class="ms-1 ms-md-2">
                    <img
                      src="/img/new.gif"
                      v-if="
                        $moment
                          .duration($moment().diff($moment(item.date)))
                          .asDays() < 8
                      "
                    />
                  </div>
                </div>
              </td>
              <td class="d-none d-sm-table-cell w-1">
                <p class="text-date" v-if="item.timezone_date.timezone_private">
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-orange"
                    title="Connect time"
                    >CT</dfn
                  >
                  {{ item.timezone_date.timezone_private | dateformat }}
                </p>
                <p
                  class="text-date"
                  v-else-if="item.timezone_date.timezone_common"
                >
                  <dfn
                    class="time-zone-mark badge rounded-pill bg-green"
                    title="Standard time"
                    >ST</dfn
                  >
                  {{ item.timezone_date.timezone_common | dateformat }}
                </p>
              </td>
              <td class="d-none d-lg-table-cell">
                {{ item.cnt | comma }}
              </td>
              <td class="d-none d-lg-table-cell">
                <a
                  v-if="item.filename"
                  class="btn btn-primary btn-xs"
                  :href="item.download_link"
                  download
                >
                  {{ $t("button.support.download") }}
                </a>
              </td>
              <td class="td-arrow" @click="toggle_tr(index + 'l')">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index + 'l' }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index + 'l' }"
              :key="index + 'r'"
              v-if="view_hide === index + 'l'"
            >
              <td colspan="3">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.number") }}</div>
                    <div class="col-8">{{ item.no }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.type") }}</div>
                    <div class="col-8">
                      <span class="icon badge bg-purple" v-if="item.type == 1">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-blue" v-if="item.type == 2">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-pink" v-if="item.type == 3">
                        {{ item.type | category("list") }}
                      </span>
                      <span class="icon badge bg-cyan" v-if="item.type == 4">
                        {{ item.type | category("list") }}
                      </span>
                    </div>
                  </div>
                  <div class="row d-sm-none">
                    <div class="col-4">
                      {{ $t("title.common.date") }}
                    </div>
                    <div class="col-8">
                      <p
                        class="text-date"
                        v-if="item.timezone_date.timezone_private"
                      >
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-orange"
                          title="Connect time"
                          >CT</dfn
                        >
                        {{ item.timezone_date.timezone_private | dateformat }}
                      </p>
                      <p
                        class="text-date"
                        v-else-if="item.timezone_date.timezone_common"
                      >
                        <dfn
                          class="time-zone-mark badge rounded-pill bg-green"
                          title="Standard time"
                          >ST</dfn
                        >
                        {{ item.timezone_date.timezone_common | dateformat }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.support.view_count") }}
                    </div>
                    <div class="col-8">
                      {{ item.cnt | comma }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.support.download") }}</div>
                    <div class="col-8">
                      <a
                        v-if="item.filename"
                        class="btn btn-primary btn-xs"
                        :href="item.download_link"
                        download
                      >
                        {{ $t("button.support.download") }}
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";

export default {
  name: "Resources",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        type:
          this.$route.query.type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].type) ||
          0,
        pagesize:
          this.$route.query.pagesize ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pagesize) ||
          10,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      topItems: [],
      view_hide: null,
    };
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_resourceslist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_resourceslist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.get_resourceslist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_resourceslist();
    },
    "searchData.pageno": function () {
      this.get_resourceslist();
    },
  },
  methods: {
    get_resourceslist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_resourceslist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.resourceslistcnt <= 0 && data.topresourceslist.length <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.resourceslistcnt;
          this.page_total = Math.ceil(data.resourceslistcnt / this.rows);
          this.items = data.resourceslist;
          this.topItems = data.topresourceslist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_resourceslist();
  },
};
</script>
